import React, { useEffect, useState } from "react";
import { PdfIcon, PhotoSvg, VideoSvg } from "../Svgs";
import { saveAs } from "file-saver";
import DownloadButton from "../Common/ListHeaderButtons/DownloadButton";
import { getFileName } from "../../Utils/fileUtils";
import MediaPreview from "../Common/MediaPreview";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import { useSelector } from "react-redux";

const FaqPdf = ({ title, size, url, mime }) => {
  const [fileSize, setFileSize] = useState();
  const { user } = useSelector((state) => state.userReducer);

  const type = mime?.includes("image")
    ? "IMAGE"
    : mime?.includes("video")
    ? "VIDEO"
    : mime?.includes("pdf")
    ? "PDF"
    : "FICHIER";
  const [previewMedia, setPreviewMedia] = useState();

  useEffect(() => {
    (async function getFileSize() {
      try {
        const response = await fetch(url);
        if (response.ok) {
          const contentLength = response.headers.get("content-length");
          const sizeInBytes = parseInt(contentLength);
          const sizeInKb = sizeInBytes / 1024; // Convert to KB
          if (sizeInKb > 1024) {
            const sizeInMb = sizeInKb / 1024; // Convert to KB
            setFileSize(sizeInMb.toFixed(2) + " MB");
          } else {
            setFileSize(sizeInKb.toFixed(2) + " KB");
          }
        } else {
          setFileSize("-");
        }
      } catch (error) {
        setFileSize("-");
      }
    })();
  }, [size, url]);

  return (
    <div className="alignH gap-4">
      {type === "IMAGE" ? (
        <PhotoSvg />
      ) : type === "VIDEO" ? (
        <VideoSvg />
      ) : type === "PDF" ? (
        <PdfIcon />
      ) : null}
      {/* <PdfIcon /> */}
      <div>
        <span className="f14 normal text-lightBlack">{title}</span>
        <p className="f12 normal text-lightBlack mb-0">
          {type} | {fileSize}
        </p>
      </div>
      {/* <a href={url} target="_blank" rel="noopener noreferrer">
        <DownloadPdfSvg />
      </a> */}
      {(type !== "VIDEO" || user?.attributes?.userRole === 1) && (
        <div className="listHeaderButtons d-flex">
          <DownloadButton
            disabled={!url}
            callback={() =>
              saveAs(
                `${url}`,
                getFileName({
                  url,
                  srcSlug: title,
                  fileSlug: mime,
                  type: { fileName: "Fichier", src: "Guide" },
                })
              )
            }
          />
        </div>
      )}
      {type !== "PDF" && (
        <div className="listHeaderButtons d-flex">
          <button
            className="downloadButton br5 d-flex justify-content-center align-items-center border-0 me-2"
            onClick={() => {
              setPreviewMedia(url?.replaceAll(IMAGE_END_POINT, ""));
            }}
          >
            <i className="bi bi-eye text-blue f18" />
          </button>
          {previewMedia && (
            <MediaPreview
              mediaType={type?.toLowerCase()}
              isUrl={!!url}
              file={previewMedia}
              setPreviewMedia={setPreviewMedia}
              ableToDownload={
                type !== "VIDEO" || user?.attributes?.userRole === 1
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FaqPdf;
