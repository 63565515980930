import React, { useEffect, useReducer, useState } from "react";
import qs from "qs";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { resellerForm } from "./data";
import { useTranslation } from "react-i18next";
import Select from "../../Common/FormElements/Select/Select";
import { MultiSelect } from "primereact/multiselect";
import API from "../../../Api/Api";
import { API_END_POINT } from "../../../Api/EndPoints";
import SwitchButton from "../../Common/FormElements/SwitchButton/SwitchButton";

function Infromations({ initialValues, handleFormChange, customErrors }) {
  const { t, i18n } = useTranslation("common");
  const { user } = useReducer((state) => state.userReducer);
  const [selectOptions, setSelectOptions] = useState({});
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e, max) => {
    const { value, name } = e.target;
    if (max) {
      if (value <= max) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const errorsMap = {
    exist: t("This email is already in use."),
    phoneNumberError: t("Please enter a valid phone number."),
    emailError: t("The email address is not valid!"),
  };
  useEffect(() => {
    handleFormChange("infos", formData);
  }, [formData]);

  const getSelectValue = (value, options, name) => {
    const currentOptions = (options ?? []).map((option) => ({
      name: t(`${option.name}`),
      code: option.code,
    }));
    if (["number", "string", "boolean"].includes(typeof value)) {
      return currentOptions.find((option) => option.code === value);
    }
    return value;
  };

  useEffect(() => {
    (async function getData() {
      try {
        const { data: activityData } = await API.get(
          `${API_END_POINT}api/activities`
        );
        const { data: spaMarqueData } = await API.get(
          `${API_END_POINT}api/spa-marques`
        );

        const query = qs.stringify({
          populate: {
            user: {
              fields: ["firstName", "lastName"],
            },
          },
          filters: {
            marque: { id: { $eq: user?.attributes?.marque?.data?.id } },
          },
        });
        const { data: businessAffilateData } = await API.get(
          `${API_END_POINT}api/business-affiliates?${query}&populate=*&sort[0]=createdAt:desc`
        );

        setSelectOptions({
          business_affiliate: businessAffilateData?.data?.map((affiliate) => {
            const firstName =
              affiliate?.attributes?.user?.data?.attributes?.firstName ?? "";
            const lastName =
              affiliate?.attributes?.user?.data?.attributes?.firstName ?? "";
            const fullName = [firstName, lastName].join(" ")?.trim();
            return {
              name: fullName,
              code: affiliate?.id,
            };
          }),

          spaMarques: spaMarqueData?.data?.map((marque) => ({
            label: marque.attributes?.name,
            value: marque?.id,
          })),
          activities: activityData?.data?.map((activity) => ({
            label: i18n.language?.includes("en")
              ? activity.attributes?.name_en
              : activity.attributes?.name,
            value: activity?.id,
          })),
        });
      } catch {}
    })();
  }, [i18n.language]);

  return (
    <div className="flex-1 d-flex flex-column">
      <div className="modalContent px-5 pt-4 pb-5">
        <div className="row">
          {resellerForm.map((el) => (
            <div className="col-6 mt-4" key={el?.name}>
              <p className="f16 text-ultraBlack mb-2">{t(`${el?.label}`)}</p>
              {el.type === "select" ? (
                <Select
                  {...el}
                  label={el.label ? t(`${el.label}`) : ""}
                  placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                  value={getSelectValue(
                    formData[el?.name],
                    selectOptions[el.name] ?? el.options ?? [],
                    el?.name
                  )}
                  options={
                    selectOptions[el.name] ??
                    (el.options ?? []).map((option) => ({
                      name: t(`${option.name}`),
                      code: option.code,
                    }))
                  }
                  callback={handleChange}
                  appendToSelf
                />
              ) : el.type === "multiSelect" ? (
                <MultiSelect
                  {...el}
                  label={el.label ? t(`${el.label}`) : ""}
                  placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                  value={formData[el?.name]}
                  options={
                    selectOptions[el.name] ??
                    (el.options ?? []).map((option) => ({
                      name: t(`${option.name}`),
                      code: option.code,
                    }))
                  }
                  onChange={handleChange}
                  appendToSelf
                  className="h-40px w-100"
                />
              ) : el.type === "checkbox" ? (
                <SwitchButton
                  isActive={formData?.isMajorAccount}
                  filterSwitch
                  value={formData[el?.isMajorAccount]}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      isMajorAccount: !formData?.isMajorAccount,
                    })
                  }
                />
              ) : (
                <TextInput
                  {...el}
                  label={el.label ? t(`${el.label}`) : ""}
                  placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                  value={formData[el?.name]}
                  surfixText={el?.max ? `/${el?.max}` : null}
                  callback={(e) => handleChange(e, el?.max)}
                />
              )}
              {customErrors &&
                errorsMap.hasOwnProperty(customErrors[el?.name]) && (
                  <div className="mt-2 text-redCalendar f14">
                    {errorsMap[customErrors[el?.name]]}
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>

      {/* <div className="buttonsContainer d-flex justify-content-center mt-auto">
        <Button variant="outline" className="me-4">
          Annuler
        </Button>
        <Button
          loading={loading}
          disabled={disabled}
          onClick={loading ? undefined : handleClick}
        >
          Enregistrer
        </Button>
      </div> */}
    </div>
  );
}

export default Infromations;
