import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../Utils";
import AnnualMargin from "./AnnualMargin";
import { useSelector } from "react-redux";

const AnnualTurnover = ({ filters }) => {
  const { t } = useTranslation("common");
  const { user } = useSelector((state) => state.userReducer);
  const years = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
  ];
  const [selectedYear, setSelectedYear] = useState(years[0]);
  const [data, setData] = useState([]);

  const marque = user?.attributes?.marque?.data?.id;

  const rows = [
    {
      label: "General Turnover",
      value: data?.general ?? 0,
    },
    {
      label: "Store Spa Turnover",
      value: data?.byCategory?.spa ?? 0,
    },
    {
      label: "Store Swim Spa Turnover",
      value: data?.byCategory?.swimSpa ?? 0,
    },
    {
      label: "Store Accessories Turnover",
      value: data?.byCategory?.accessory ?? 0,
    },
    {
      label: "Store Parts Turnover",
      value: data?.byCategory?.sparePart ?? 0,
    },
    {
      label: "Marketing Product Turnover",
      value: data?.byCategory?.marketingProduct ?? 0,
    },
    {
      label: "Key Accounts Turnover",
      value: data?.byCategory?.bigAccount ?? 0,
    },
  ];

  const fetchData = async () => {
    try {
      const params = {
        year: selectedYear,
      };

      Object.keys(filters)?.forEach((key) => {
        if (filters[key]) {
          params[key] = filters[key]?.code;
        }
      });

      const response = await API.get(`api/annual-turnover`, {
        params: {
          ...params,
          marque,
        },
      });

      setData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear, filters]);

  return (
    <div className="pt-5 w-100">
      <span className="text-blue bold f22 quickSand">
        {t("Detailed Turnover")}
      </span>
      <div className="alignH mt-2">
        {years.map((year) => (
          <button
            key={year}
            className={`${
              year === selectedYear
                ? "bg-gray-500 text-white"
                : "bg-transparent text-blue"
            } py-1 f16 px-3 border-0 outline-none sBold`}
            onClick={() => {
              if (year !== selectedYear) {
                setSelectedYear(year);
              }
            }}
          >
            {year}
          </button>
        ))}
      </div>
      <div className="d-flex gap-4 w-100 mt-3">
        <div className="w-50 flex-column d-flex gap-1">
          {rows.map(({ label, value }) => (
            <div className="d-flex justify-content-between gap-1">
              <span className="f16 py-2 px-4 text-lightBlack sBold f14 w70 bg-dimGrey">
                {t(`${label}`)}
              </span>
              <span className="f16 py-2 px-4 text-lightBlack sBold f14 w30 bg-dimGrey text-center">
                {formatCurrency(value)}
              </span>
            </div>
          ))}
        </div>
        <AnnualMargin filters={filters} selectedYear={selectedYear} />
      </div>
    </div>
  );
};

export default AnnualTurnover;
