import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCart,
  removeOrderFromCartsList,
  switchCarts,
  updateCart,
} from "../../REDUX/actions/catalogue";
import "./CartsSelector.css";
import { Spinner } from "react-bootstrap";
import { openCenteredModal } from "../../REDUX/actions/centeredModal";
import DeleteCommand from "../Commandes/DeleteCommand";
import { useTranslation } from "react-i18next";

const tabsMap = {
  Products: "Produits",
  Accessories: "Accessoires",
  "Marketing Products": "Produits marketing",
  "Spare Part": "Pièce Détachée",
  Services: "Services",
  "Other branded products": "Produits d'autres marques",
  SPA: "SPA",
  "SWIM SPA": "SPA DE NAGE",
  SAUNA: "SAUNA",
};

const CartSelector = ({ searchInput, activeTab, productsactiveTab }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const { allCarts, cart, newCartLoading } = useSelector(
    (state) => state.catalogueReducer
  );

  return allCarts?.length ? (
    <div className="d-flex  mb-3 gap-2 cartSelectorContainer alignH ">
      {allCarts?.map((cartElt) => (
        <div
          onClick={() => {
            if (cartElt?.id !== cart?.id) {
              dispatch(switchCarts(cartElt?.id));
            }
          }}
          key={cartElt?.id}
          className={`position-relative alignH gap-4 mx-2 bold cartSelectorItem pointer p-3 ${
            cartElt?.id === cart?.id ? " cartSelected" : "cartUnSelected"
          }`}
        >
          <div>
            <div className="f10">
              {cartElt?.isValidated ? t("Modification") : t("Creation")}
            </div>
            {cartElt?.isValidated ? t("Order") : t("Draft")} #{cartElt?.number}
          </div>

          <div
            className="deleteCart alignC t02"
            onClick={
              () => {
                dispatch(
                  removeOrderFromCartsList(cartElt?.id, allCarts[0]?.id)
                );
              }

              // dispatch(
              //   openCenteredModal(
              //     <DeleteCommand
              //       commandeNumber={cartElt?.number}
              //       commandeId={cartElt?.id}
              //       searchInput={searchInput}
              //       activeTab={tabsMap[activeTab]}
              //       productsactiveTab={tabsMap[productsactiveTab]}
              //     />
              //   )
              // )
            }
          >
            {/* <span className="text-lameRed pointer f14">X</span> */}
            <i class="bi bi-x-lg text-lameRed pointer f12"></i>
            {/* <i className="bi bi-trash3 f14 text-lameRed pointer t02" /> */}
          </div>
        </div>
      ))}
      {newCartLoading ? (
        <Spinner size="sm" variant="blue" />
      ) : (
        <div
          onClick={() => {
            dispatch(
              createCart({
                searchInput,
                activeTab: tabsMap[activeTab],
                productsactiveTab: tabsMap[productsactiveTab],
              })
            );
            // if (cartElt.id !== cart.id) {
            //   dispatch(switchCarts(cartElt.id));
            // }
          }}
          // key={cartElt.id}
          className={`pointer addCartButton alignC bold`}
        >
          +
        </div>
      )}
    </div>
  ) : null;
};

export default CartSelector;
