import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TabsNavbar from "../Common/TabsNavBar/TabsNavbar";
import Button from "../Common/FormElements/Button/Button";
import { closeRightModal } from "../../REDUX/actions/rightModal";
import TabContent from "../MonEquipe/Modals/NewMember/TabContent";
import { addGuide } from "../../REDUX/actions/guides";
import { useTranslation } from "react-i18next";

const NewGuide = () => {
  const { t, i18n } = useTranslation("common");
  const [language, setLanguage] = useState("fr");
  const [activeTab, setActiveTab] = useState("Question");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [attachments, setAttachments] = useState([]);
  const { guideLoading: loading } = useSelector((state) => state.guidesReducer);

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    const dataTosend = {
      ...formData,
      type: formData?.type?.code,
      category: formData?.category?.code,
      model: formData?.model?.code,
      gamme: formData?.range?.code,
      locale: language,
    };

    // closeRightModal();
    try {
      const localeFilter = i18n.language.includes("en")
        ? { locale: { $eq: "en" } }
        : {
            $and: [
              { $or: [{ locale: { $eq: "fr" } }, { locale: { $null: true } }] },
            ],
          };
      dispatch(addGuide(dataTosend, attachments, t, localeFilter));
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const formDataIncomplete = !(
    formData.title &&
    formData.description &&
    formData.type
  );

  return (
    <div className="d-flex flex-column h100 justify-content-between">
      <div>
        <h1 className="quickSand f25 sBold text-blue mb-5 px-5">
          {t("Add new guide")}
        </h1>
        <TabsNavbar
          tabs={["Question", "Files"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabContent
          activeTab={activeTab}
          formData={formData}
          setFormData={setFormData}
          attachments={attachments}
          setAttachments={setAttachments}
          language={language}
          setLanguage={setLanguage}
        />
      </div>
      <div className="align-self-center alignH gap-3">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
        >
          {t("Cancel")}
        </Button>
        <Button
          className="px-5"
          onClick={handleSubmit}
          formDataIncomplete={formDataIncomplete}
          loading={loading}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default NewGuide;
