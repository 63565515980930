import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { generateOptionsBasedOnRole } from "../../Utils/savUtils";
import VerticalPoints from "../Common/VerticalPoints/VerticalPoints";
import { TicketProblemPicker } from "./NewTicketSav/TicketProblemPicker";
import TicketStatusTag from "./NewTicketSav/TicketStatusTag";
import { openRightModal } from "../../REDUX/actions/rightModal";
import UpdateTicketSav from "./UpdateTicketSavAdmin/UpdateTicketSav";
import { handleValue, isDefined } from "../../Utils";
import { dateInPast } from "../../Utils/pastDate";
import { useTranslation } from "react-i18next";

const SavCard = ({
  id,
  date,
  deliveryDate,
  refClient,
  status,
  problem,
  users,
  relaunchDate,
  reseller,
  intervention,
}) => {
  const { t } = useTranslation("common");
  const { user, userRole } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const responsable = users.find((user) => user.userRole === 1);

  const userOptions = generateOptionsBasedOnRole(userRole, id, dispatch, t);
  const handleUpdate = () => {
    dispatch(openRightModal(<UpdateTicketSav id={id} />));
  };

  const interventionDate = new Date(intervention?.attributes?.date);
  const todaysDate = new Date();

  return (
    <div
      className="row px-4 py-4 d-flex justify-content-between align-items-center w-100 m-0 pointer"
      onClick={handleUpdate}
    >
      <span className="text-softBlue normal f14 col-1">{id}</span>
      <span className="text-brightGrey normal f14 col-1">
        {moment(date).format("DD-MM-YYYY")}
      </span>
      {user?.attributes?.userRole !== 0 && (
        <>
          <div className="d-flex flex-column gap-1  col-1">
            <span className="text-brightGrey normal f14 ellipsis-text">
              {handleValue(reseller?.attributes?.name)}
            </span>
          </div>
          {user?.attributes?.userRole === 1 && (
            <span className="text-brightGrey normal f14 col-1 ellipsis-text">
              {responsable
                ? responsable.firstName + " " + responsable.lastName
                : "-"}
            </span>
          )}
        </>
      )}

      <span className="text-brightGrey normal f14 col-1">
        {deliveryDate ? moment(deliveryDate).format("DD-MM-YYYY") : "-"}
      </span>
      {user?.attributes?.userRole === 0 && (
        <span className="text-brightGrey normal f14 col-1">
          {refClient ?? "-"}
        </span>
      )}
      <div className="col-1 ">
        <TicketStatusTag status={status} />
      </div>
      <span className="text-brightGrey normal f14 col-1 ">
        {TicketProblemPicker(problem)}
      </span>
      {user?.attributes?.userRole === 1 && (
        <span className="text-brightGrey normal f14 col-1">
          {relaunchDate && status !== 2
            ? moment(relaunchDate)?.format("DD-MM-YYYY")
            : "-"}
        </span>
      )}
      <span className="text-brightGrey normal f14 col-1 ">
        {!isDefined(intervention) ? (
          "-"
        ) : dateInPast(interventionDate, todaysDate) ? (
          <FontAwesomeIcon icon={faCircleCheck} size="xl" color={"#1ad598"} />
        ) : (
          <FontAwesomeIcon icon={faCircleXmark} size="xl" color={"#ff0d0d"} />
        )}
      </span>
      <div className="text-brightGrey textEnd normal f14 col-1 d-flex justify-content-end">
        <VerticalPoints showLeft options={userOptions} />
      </div>
    </div>
  );
};

export default SavCard;
