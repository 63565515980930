import qs from "qs";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import { deleteRecord } from "../../Utils/api";
import { isDefined, uploadFile } from "../../Utils";
import { toast } from "react-toastify";
import { updateDelivery } from "./panier";
import store from "../store";
import _ from "lodash";
import moment from "moment";

export const getResellers =
  (search, reset = false, isArchived = false, extraFilters) =>
  async (dispatch) => {
    try {
      if (reset) dispatch({ type: "RESET_RESELLERS" });
      const { resellers: currentResellers, resellersCount } =
        store.getState().resellerReducer;

      const currentPage = Math.ceil(currentResellers?.length / 10);
      const page = currentPage + 1;
      if (
        (isDefined(resellersCount) && currentPage * 10 >= resellersCount) ||
        resellersCount === 0
      )
        return;
      dispatch({ type: "FETCH_RESELLERS_LOADING" });
      const { userRole, user } = store.getState().userReducer;
      const businessBrokerId = user?.attributes?.business_affiliate?.data?.id;
      const userMarqueId = user?.attributes?.marque?.data?.id;
      const filters = {
        name: { $containsi: search },
        marque: { id: { $eq: userMarqueId } },
      };
      // if the userRole is a businessBroker then add its filters with assing Object js method
      if (isArchived) {
        Object.assign(filters, {
          archived: { $eq: true },
        });
      } else if (isArchived === false) {
        Object.assign(filters, {
          $or: [
            {
              archived: { $eq: false },
            },
            {
              archived: { $null: true },
            },
          ],
        });
      }

      if (userRole === "BusinessBroker") {
        Object.assign(filters, {
          business_affiliate: { id: { $eq: businessBrokerId } },
        });
      }
      if (isDefined(extraFilters?.business_affiliate?.code)) {
        Object.assign(filters, {
          business_affiliate: {
            id: { $eq: extraFilters?.business_affiliate?.code },
          },
        });
      }
      if (isDefined(extraFilters?.country?.code)) {
        Object.assign(filters, {
          delivery_addresses: {
            country: {
              id: {
                $eq: extraFilters?.country?.code,
              },
            },
          },
        });
      }
      const query = qs.stringify({
        populate: [
          "business_affiliate.user",
          "profils",
          "delivery_addresses.city",
          "delivery_addresses.country",
          "billingAddress.city",
          "billingAddress.country",
          "orders",
          "commandes",
          "marque",
          "spaMarques",
        ],
        filters: filters,
      });
      const {
        data: { data: resellerData, meta },
      } = await API.get(
        `${API_END_POINT}api/organizations?${query}&sort[0]=createdAt:desc&pagination[page]=${page}`,
        {
          params: {
            pagination: {
              pageSize: 10,
            },
          },
        }
      );

      dispatch({
        type: "FETCH_RESELLERS_SUCCESS",
        payload:
          page === 1
            ? resellerData
            : _.uniqBy([...currentResellers, ...resellerData], function (item) {
                return item?.id;
              }),
      });
      dispatch({
        type: "RESELLERS_COUNT",
        payload: meta?.pagination?.total,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: "FETCH_RESELLERS_ERROR", payload: error });
    }
  };

export const getCurrentReseller = (id) => async (dispatch) => {
  try {
    if (!id) return;
    dispatch({ type: "FETCH_CURRENT_RESELLER_LOADING" });
    const query = qs.stringify({
      populate: [
        "spaMarques",
        "business_affiliate.user",
        "profils",
        "commandes",
        "billingAddress.city",
        "billingAddress.country",
        "delivery_addresses.city",
        "delivery_addresses.country",
        "orders.delivery",
        "activities",
        "note",
        "marque",
        "contract.file",
        "contract.warranty",
        "contract.area",
        "contract.products",
        "contract.pvls",
        "assistance.furnitures",
      ],
    });
    const {
      data: { data: resellerData },
    } = await API.get(`${API_END_POINT}api/organizations/${id}?${query}`);

    dispatch({ type: "FETCH_CURRENT_RESELLER_SUCCESS", payload: resellerData });
  } catch (error) {
    dispatch({ type: "FETCH_CURRENT_RESELLER_ERROR", payload: error });
  }
};

export const createUpdateReseller =
  (
    { infos, addresses, note, contract, accompaniment, affiliate },
    id,
    callback = () => {},
    t
  ) =>
  async (dispatch, getState) => {
    const { user } = getState().userReducer;
    const marque = user?.attributes?.marque?.data?.id;
    try {
      dispatch({ type: "CREATE_UPDATE_RESELLER_LOADING" });
      const query = qs.stringify({
        populate: [
          "business_affiliate.user",
          "profils",
          "delivery_addresses.city",
          "delivery_addresses.country",
          "billingAddress.city",
          "billingAddress.country",
          "marque",
          "spaMarques",
        ],
      });

      const poolSpaGroup = infos?.poolSpaGroup?.code;
      const traffic = infos?.traffic?.code;
      const storeArea = infos?.storeArea?.code;
      const business_affiliate = infos?.business_affiliate?.code;

      const {
        data: { data: createdReseller },
      } = await API[isDefined(id) ? "put" : "post"](
        `${API_END_POINT}api/organizations/${id ?? ""}?${query}`,
        {
          data: {
            ...infos,
            poolSpaGroup,
            traffic,
            storeArea,
            business_affiliate,
            marque,
            isMajorAccount: infos?.isMajorAccount ?? false,
          },
        }
      );

      await dispatch(createUpdateNote(note, createdReseller?.id));
      await dispatch(createUpdateContract(contract, createdReseller?.id));
      await dispatch(
        createUpdateAccompaniment(accompaniment, createdReseller?.id)
      );
      // await dispatch(
      //   createUpdateAddress(
      //     addresses.delivery,
      //     addresses.billing,
      //     createdReseller?.id
      //   )
      // );
      const {
        data: { data: resellerData },
      } = await API.get(
        `${API_END_POINT}api/organizations/${createdReseller?.id}`,
        {
          populate: [
            "business_affiliate.user",
            "profils",
            "commandes",
            "billingAddress.city",
            "billingAddress.country",
            "delivery_addresses.city",
            "delivery_addresses.country",
            "orders.delivery",
            "activities",
            "note",
            "marque",
            "spaMarques",
            "contract.file",
            "contract.warranty",
            "contract.area",
            "contract.products",
            "contract.pvls",
            "assistance.furnitures",
          ],
        }
      );
      // await dispatch(
      //   updateResellerAffiliates(
      //     affiliate
      //       .map((item) => item?.code)
      //       ?.filter((item) => isDefined(item?.code)),
      //     createdReseller?.id
      //   )
      // );

      await dispatch({
        type: "CREATE_UPDATE_RESELLER_SUCCESS",
        payload: resellerData,
      });
      callback();

      if (isDefined(id)) {
        toast.success(t("The reseller has been updated successfully!"));
      } else {
        toast.success(t("Reseller created successfully!"));
      }
    } catch (error) {
      console.log({ error });
      if (isDefined(id)) {
        toast.error(t("An error occurred while updating the reseller"));
      } else {
        toast.error(t("An error occurred while creating the reseller"));
      }
      dispatch({ type: "CREATE_UPDATE_RESELLER_ERROR", payload: error });
    }
  };

export const createUpdateNote =
  (formData, resellerId) => async (dispatch, getState) => {
    try {
      const { currentReseller } = getState().resellerReducer;
      const id = currentReseller?.attributes?.note?.data?.id;

      await API[isDefined(id) ? "put" : "post"](
        `${API_END_POINT}api/notes/${id ?? ""}`,
        {
          data: {
            ...formData,
            reseller: resellerId,
          },
        }
      );
    } catch {}
  };

export const createUpdateContract =
  (formData, resellerId) => async (dispatch, getState) => {
    try {
      const { currentReseller } = getState().resellerReducer;
      const id = currentReseller?.attributes?.contract?.data?.id;
      const { warranty, file, area, ...rest } = formData;

      const files = {};

      if (warranty instanceof File) {
        files.warranty = await uploadFile(warranty);
      }
      if (file instanceof File) {
        files.file = await uploadFile(file);
      }
      if (area instanceof File) {
        files.area = await uploadFile(area);
      }

      const { data } = await API[isDefined(id) ? "put" : "post"](
        `${API_END_POINT}api/contracts/${id ?? ""}`,
        {
          data: {
            ...rest,
            ...files,
            reseller: resellerId,
          },
        }
      );
    } catch {}
  };

export const createUpdateAccompaniment =
  (formData, resellerId) => async (dispatch, getState) => {
    try {
      const { currentReseller } = getState().resellerReducer;
      const id = currentReseller?.attributes?.assistance?.data?.id;

      const {
        scale,
        transaction,
        branding,
        touch,
        commercialTraining,
        technicalTraining,
      } = formData;

      const { data } = await API[isDefined(id) ? "put" : "post"](
        `${API_END_POINT}api/assistances/${id ?? ""}`,
        {
          data: {
            ...formData,
            scale: scale?.code,
            transaction: transaction?.code,
            branding: branding?.code,
            touch: touch?.code,
            commercialTraining: commercialTraining?.code,
            technicalTraining: technicalTraining?.code,
            reseller: resellerId,
          },
        }
      );
    } catch {}
  };

export const createUpdateAddress =
  (formData, secondFormData, id) => async (dispatch, getState) => {
    try {
      const { currentReseller } = getState().resellerReducer;
      dispatch({ type: "CREATE_RESELLER_ADDRESS_LOADING" });
      const query = qs.stringify({
        populate: ["country", "city", "reseller"],
      });
      const deliveryAddressId =
        currentReseller?.attributes?.delivery_addresses?.data[0]?.id;
      const deliveryAddressIds =
        currentReseller?.attributes?.delivery_addresses?.data
          ?.filter((item) => {
            return deliveryAddressId !== item?.id && isDefined(item?.id);
          })
          ?.map(({ id }) => id);

      const {
        data: { data: deliveryAddressData },
      } = await API[isDefined(deliveryAddressId) ? "put" : "post"](
        `${API_END_POINT}api/addresses/${deliveryAddressId ?? ""}?${query}`,
        {
          data: {
            ...formData,
            country: formData?.country?.code,
            city: formData?.city?.code,
          },
        }
      );

      const billingAddressId =
        currentReseller?.attributes?.billingAddress?.data[0]?.id;

      const {
        data: { data: billingAddressData },
      } = await API[isDefined(billingAddressId) ? "put" : "post"](
        `${API_END_POINT}api/addresses/${billingAddressId ?? ""}?${query}`,
        {
          data: {
            ...secondFormData,
            country: secondFormData?.country?.code,
            city: secondFormData?.city?.code,
          },
        }
      );
      await API.put(`${API_END_POINT}api/organizations/${id}`, {
        data: {
          delivery_addresses: [deliveryAddressData?.id, ...deliveryAddressIds],
          billingAddress: billingAddressData?.id,
        },
      });

      await dispatch({
        type: "CREATE_RESELLER_ADDRESS_SUCCESS",
        payload: { billingAddressData, deliveryAddressData },
      });
    } catch (error) {
      dispatch({ type: "CREATE_RESELLER_ADDRESS_ERROR", payload: error });
      console.log(error);
    }
  };

export const updateResellerAffiliates =
  (affiliates = [], id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "CREATE_UPDATE_RESELLER_LOADING" });
      const query = qs.stringify({
        populate: [
          "business_affiliate.user",
          "profils",
          "delivery_addresses.city",
          "delivery_addresses.country",
          "billingAddress.city",
          "billingAddress.country",
        ],
      });

      const {
        data: { data: resellerData },
      } = await API.put(`${API_END_POINT}api/organizations/${id}?${query}`, {
        data: {
          business_affiliates: affiliates,
        },
      });
      dispatch({
        type: "CREATE_UPDATE_RESELLER_SUCCESS",
        payload: resellerData,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "CREATE_UPDATE_RESELLER_ERROR",
        payload: error,
      });
    }
  };

export const deleteReseller =
  (id, deliveryAddressId, billingAddressId, t) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_RESELLER_LOADING" });
      await deleteRecord(id, "organizations");
      await deleteRecord(deliveryAddressId, "addresses");
      await deleteRecord(billingAddressId, "addresses");
      dispatch({ type: "DELETE_RESELLER_SUCCESS", payload: id });
      toast.success(t("Reseller deleted successfully!"));
    } catch (error) {
      toast.error(
        t(
          "Reseller deleted successfully! An error occurred while deleting the reseller"
        )
      );
      dispatch({ type: "DELETE_RESELLER_ERROR", payload: error });
    }
  };
export const resetCurrentReseller = () => (dispatch) => {
  dispatch({ type: "RESET_RESELLER" });
};

export const getSelectedReselerAdress = (payload) => async (dispatch) => {
  const { id } = payload;
  const panier = store.getState().panierReducer.panier;

  try {
    dispatch({ type: "FETCH_SELECTED_RESELLER_ADRESS" });
    const {
      data: { data },
    } = await API.get(`api/addresses/${id}?populate=*`);

    const street = data?.attributes?.street;
    const zipCode = data?.attributes?.zip;
    const city = data?.attributes?.city?.data?.attributes?.name;
    const country = data?.attributes?.country?.data?.attributes?.name;

    dispatch(
      updateDelivery({
        deliveryID: panier?.delivery?.id,
        addressId: id,
        panierID: panier?.id,
        billingAddress: `${street}, ${zipCode} ${city}, ${country}`,
        deliveryAddress: `${street}, ${zipCode} ${city}, ${country}`,
      })
    );
    dispatch({
      type: "SELECTED_RESELLER_ADRESS_SUCCESS",
      // payload: selectedAdress,
    });
  } catch (error) {
    dispatch({ type: "SELECTED_RESELLER_ADRESS_FAIL", payload: error });
    toast.error("un erreur est servenue");
    console.log(error);
  }
};

export const archiveReseller = (id) => async (dispatch) => {
  if (!isDefined(id)) return;
  try {
    await API.put(`${API_END_POINT}api/organizations/${id}`, {
      data: {
        archived: true,
      },
    });
    dispatch({ type: "RESELLER_ARCHIVED", payload: id });
  } catch (err) {
    console.log(err);
  }
};

export const unarchiveReseller = (id) => async (dispatch) => {
  if (!isDefined(id)) return;
  try {
    await API.put(`${API_END_POINT}api/organizations/${id}`, {
      data: {
        archived: false,
      },
    });
    dispatch({ type: "RESELLER_UNARCHIVED", payload: id });
  } catch (err) {
    console.log(err);
  }
};

export const getResellerComments = (reseller) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_COMMENTS_RESELLER_LOADING" });
    const query = qs.stringify({
      populate: ["attachments.file", "user.photo", "user.marque"],
      sort: ["createdAt:desc"],
      filters: {
        reseller: { id: { $eq: reseller } },
      },
    });
    const {
      data: { data: commentData },
    } = await API.get(`${API_END_POINT}api/comments?${query}`);
    dispatch({ type: "FETCH_COMMENTS_RESELLER_SUCCESS", payload: commentData });
  } catch (error) {
    dispatch({ type: "FETCH_COMMENTS_RESELLER_ERROR", payload: error });
  }
};

export const createResellerComment =
  (comment, reseller, user, successCallback, t) => async (dispatch) => {
    dispatch({ type: "CREATE_COMMENTS_RESELLER_LOADING" });

    try {
      const {
        data: { data: commentData },
      } = await API.post(`${API_END_POINT}api/comments?populate=*`, {
        data: {
          text: comment?.text,
          reseller,
          user,
        },
      });

      if (comment?.attachments?.length > 0) {
        for (const file of comment?.attachments) {
          const fileId = await uploadFile(file);
          const { data } = await API.post(
            `${API_END_POINT}api/attachments?populate=*`,
            {
              data: {
                date: new Date(),
                file: fileId,
                comment: commentData?.id,
              },
            }
          );
          commentData.attributes.attachments.data?.push(data?.data);
        }
      }

      dispatch({
        type: "CREATE_COMMENTS_RESELLER_SUCCESS",
        payload: {
          ...commentData,
          attributes: { ...commentData?.attributes },
        },
      });
      successCallback();
      toast.success(t("A comment has been added"));
    } catch (error) {
      console.log(error);
    }
  };

export const getResellerStats = (filters) => async (dispatch, getState) => {
  try {
    const { user } = getState().userReducer;
    const userRole = user?.attributes?.userRole;
    const userMarqueId = user?.attributes?.marque?.data?.id;
    const config = {
      params: {},
    };
    if ([1, 2]?.includes(userRole)) {
      config.params.reseller = filters?.reseller?.code;
      config.params.country = filters?.country?.code;
      config.params.marque = userMarqueId;
      if (userRole === 1) {
        config.params.business_affiliate = filters?.business_affiliate?.code;
      }
    }

    dispatch({ type: "RESELLER_STATS_LOADING" });
    const { data } = await API.get(
      `${API_END_POINT}api/organizations-stats`,
      config
    );
    dispatch({ type: "RESELLER_STATS_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "RESELLER_STATS_ERROR", payload: error });
  }
};

export const getResellerEvents =
  (page, year, filters, search) => async (dispatch) => {
    try {
      const { user } = store.getState().userReducer;
      const userRole = user?.attributes?.userRole;
      let userFilters = {};
      if (userRole === 0) {
        userFilters = {
          resellers: { profils: { user: { id: { $eq: user?.id } } } },
        };
      } else if (userRole === 2) {
        userFilters = {
          resellers: {
            business_affiliate: { user: { id: { $eq: user?.id } } },
          },
        };
      }

      const searchFilters =
        search?.trim()?.length > 0
          ? {
              $or: [
                {
                  resellers: {
                    name: {
                      $containsi: search?.trim(),
                    },
                  },
                },
                {
                  title: {
                    $containsi: search?.trim(),
                  },
                },
              ],
            }
          : {};

      const pageSize = 10;
      dispatch({ type: "RESELLER_EVENTS_LOADING" });
      const { data } = await API.get(`${API_END_POINT}api/events`, {
        params: {
          pagination: {
            page,
            pageSize,
            withCount: true,
          },
          populate: {
            resellers: {
              fields: ["id", "name"],
            },
          },
          filters: {
            startDate: {
              $gte: moment(`${year}-01-01`).startOf("day").toISOString(),
              $lte: moment(`${year}-12-31`).endOf("day").toISOString(),
            },
            ...userFilters,
            ...filters,
            ...searchFilters,
          },
        },
      });

      if (page === 1) {
        dispatch({
          type: "INITIAL_RESELLER_EVENTS_SUCCESS",
          payload: data?.data,
        });
        dispatch({
          type: "RESELLER_EVENTS_PAGES",
          payload: data?.meta?.pagination?.pageCount,
        });
      } else {
        dispatch({ type: "RESELLER_EVENTS_SUCCESS", payload: data?.data });
      }
    } catch (error) {
      dispatch({ type: "RESELLER_EVENTS_ERROR", payload: error });
    }
  };
