const initialState = {
  catalogue: [],
  catalogueLoading: false,
  catalogueError: null,
  cart: {},
  allCarts: [],
  cartLoading: null,
  cartError: null,
  newCartLoading: false,
};
const catalogueReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CATALOGUE_LOADING":
      return { ...state, catalogueLoading: true, catalogueError: null };
    case "NEW_CART_LOADING":
      return { ...state, newCartLoading: true, catalogueError: null };
    case "SWITCH_CARTS":
      return {
        ...state,
        cart: state.allCarts.filter((el) => el.id === payload)[0] || {},
      };
    case "DELETE_COMMANDE":
      const newCarts = state.allCarts.filter((el) => el.id !== payload);
      return {
        ...state,

        allCarts: newCarts,
        cart: newCarts[0] || {},
      };

    case "CATALOGUE_SUCCESS":
      return {
        ...state,
        catalogueLoading: false,
        catalogueError: null,
        catalogue: payload,
      };
    case "CART_LOADING":
      return { ...state, cartLoading: payload, cartError: null };

    case "CART_VALIDATED":
      return { ...state, cartLoading: null, cartError: null };
    case "CART_SUCCESS":
      const selectedCart = localStorage.getItem("selectedCart");

      return {
        ...state,
        cartLoading: null,
        newCartLoading: false,
        cartError: null,
        cart: selectedCart
          ? payload.filter(
              (el) => el.id.toString() === selectedCart.toString()
            )[0] ||
            payload[0] ||
            {}
          : payload[0] || {},
        allCarts: payload,
      };
    case "REFETCH_CART":
      return {
        ...state,
        cartLoading: null,
        cartError: null,
        cart: payload,
        allCarts: state.allCarts.map((el) =>
          el.id === payload.id ? payload : el
        ),
      };
    case "CART_ERROR":
      return {
        ...state,
        cartLoading: null,
        cartError: null,
      };
    case "REMOVE_FROM_CARTS":
      const newAllCarts = state.allCarts.filter(({ id }) => id !== payload);
      return {
        ...state,
        allCarts: newAllCarts,
        cart: newAllCarts[0] || {},
      };
    default:
      return state;
  }
};
export default catalogueReducer;
