export const resellerForm = [
  {
    type: "text",
    label: "Company name",
    name: "name",
    required: true,
  },
  {
    type: "text",
    label: "Company phone number",
    name: "phoneNumber",
    required: true,
  },
  {
    type: "email",
    label: "Company e-mail",
    name: "email",
    required: true,
  },
  {
    type: "text",
    label: "Code VAT",
    name: "codeTVA",
  },
  {
    type: "number",
    label: "SIRET number",
    name: "siret",
  },
  {
    type: "text",
    label: "Website",
    name: "website",
  },
  {
    type: "select",
    label: "M2 in store",
    name: "storeArea",
    options: [
      {
        name: "Less than 50 m2",
        code: "Less than 50 m2",
      },
      {
        name: "50-100 m2",
        code: "50-100 m2",
      },
      {
        name: "100-150 m2",
        code: "100-150 m2",
      },
      {
        name: "150–250 m2",
        code: "150–250 m2",
      },
      {
        name: "250-350 m2",
        code: "250-350 m2",
      },
      {
        name: "More than 350 m2",
        code: "More than 350 m2",
      },
    ],
  },
  {
    type: "multiSelect",
    label: "Company activities",
    placeholder: "Select",
    name: "activities",
    appendToSelf: true,
  },
  {
    type: "number",
    label: "Number of spa in store",
    name: "spaCount",
  },
  {
    type: "number",
    label: "Number of swim spa in store",
    name: "swimSpaCount",
  },
  {
    type: "select",
    label: "Pool/Spa Group",
    placeholder: "Select",
    name: "poolSpaGroup",
    options: [
      { name: "Dejoyaux", code: "Dejoyaux" },
      { name: "Piscine Ibiza", code: "Piscine Ibiza" },
      { name: "Piscine de France", code: "Piscine de France" },
      { name: "Aboral Piscine", code: "Aboral Piscine" },
      { name: "Alliance Piscines", code: "Alliance Piscines" },
      { name: "Aquilius Piscines", code: "Aquilius Piscines" },
      { name: "Bel'O Piscine", code: "Bel'O Piscine" },
      { name: "Blue Lagoon Spas", code: "Blue Lagoon Spas" },
      { name: "Caron Piscines", code: "Caron Piscines" },
      { name: "Cocktail Piscine", code: "Cocktail Piscine" },
      { name: "EuroPiscine", code: "EuroPiscine" },
      { name: "Irripiscine", code: "Irripiscine" },
      { name: "Hydrosud", code: "Hydrosud" },
      { name: "L'eau bien être", code: "L'eau bien être" },
      { name: "L'esprit Piscine", code: "L'esprit Piscine" },
      { name: "Fusion Piscine", code: "Fusion Piscine" },
      { name: "Mondial Piscine", code: "Mondial Piscine" },
      { name: "Neptune Piscines", code: "Neptune Piscines" },
      { name: "Oasis Piscines", code: "Oasis Piscines" },
      { name: "Carré Bleu", code: "Carré Bleu" },
      { name: "Magiline", code: "Magiline" },
      { name: "Solid Pool", code: "Solid Pool" },
    ],
    filter: true,
  },
  {
    type: "multiSelect",
    label: "Sold spa brand",
    placeholder: "Select",
    name: "spaMarques",
    appendToSelf: true,
  },
  {
    type: "select",
    label: "Business affiliate",
    placeholder: "Select",
    name: "business_affiliate",
    appendToSelf: true,
  },
  {
    type: "select",
    label: "Busy Street / Commercial Zone",
    placeholder: "Select",
    name: "traffic",
    options: [
      {
        name: "Low",
        code: "low",
      },
      {
        name: "Medium",
        code: "medium",
      },
      {
        name: "High",
        code: "high",
      },
    ],
  },
  {
    type: "number",
    label: "Note on the internet",
    name: "internetNote",
    max: 5,
  },
  {
    type: "text",
    label: "Manager name",
    name: "managerName",
  },
  {
    type: "text",
    label: "Manager phone number",
    name: "managerPhoneNumber",
  },
  {
    type: "text",
    label: "Manager email",
    name: "managerEmail",
  },
  {
    type: "checkbox",
    label: "Major account",
    name: "isMajorAccount",
  },
];

export const deliveryAddressForm = [
  {
    type: "text",
    label: "Adresse de livraison",
    name: "street",
    required: true,
  },
  {
    type: "text",
    label: "Code postal",
    name: "zip",
    required: true,
  },
  {
    type: "select",
    label: "Pays",
    placeholder: "Sélectionner",
    name: "country",
    required: true,
  },
  {
    type: "select",
    label: "Ville",
    placeholder: "Sélectionner",
    name: "city",
    subOption: "country",
    optionsName: "cities",
    required: true,
  },
];

export const billingAddressForm = [
  {
    type: "text",
    label: "Adresse de facturation",
    name: "street",
    required: true,
  },
  {
    type: "text",
    label: "Code postal",
    name: "zip",
    required: true,
  },
  {
    type: "select",
    label: "Pays",
    placeholder: "Sélectionner",
    name: "country",
    required: true,
  },
  {
    type: "select",
    label: "Ville",
    placeholder: "Sélectionner",
    name: "city",
    subOption: "country",
    optionsName: "cities",
    required: true,
  },
];

export const noteForm = [
  {
    type: "number",
    label: "Note",
    name: "globalNote",
    offset: 6,
    max: 20,
    disabled: true,
  },
  {
    type: "number",
    label: "Store note: zone and dimension",
    name: "storeNote",
    max: 5,
  },
  {
    type: "number",
    label: "Communication note: digital, network, radio, billboard",
    name: "communicationNote",
    max: 5,
  },
  {
    type: "number",
    label: "Event note: trade show, fair, and other events",
    name: "eventNote",
    max: 5,
  },
  {
    type: "number",
    label: "Service note: customer feedback, technical service",
    name: "serviceNote",
    max: 5,
  },
  {
    type: "textarea",
    label: "Comment",
    name: "comment",
  },
];

export const contractForm = [
  {
    type: "number",
    label: "Objectif Spa/Contract",
    name: "objectif",
    offset: 6,
  },
  {
    type: "file",
    label: "Import the contract",
    name: "file",
  },
  {
    type: "file",
    label: "Import the warranty",
    name: "warranty",
  },
  {
    type: "image",
    label: "Import photo of the store area",
    name: "area",
  },
  {
    type: "multiSelect",
    label: "List of spas/swim spas displayed in the store",
    placeholder: "Select",
    name: "products",
    appendToSelf: true,
    filter: true,
  },
  {
    type: "multiSelect",
    label: "List of PVL in the store",
    placeholder: "Select",
    name: "pvls",
    appendToSelf: true,
    filter: true,
  },
];

export const accompanimentForm = [
  {
    type: "number",
    label: "Poseidon invest",
    name: "investment",
    euro: true,
  },
  {
    type: "select",
    label: "Commercial Training",
    placeholder: "Select",
    name: "commercialTraining",
    options: [
      {
        name: "Low",
        code: "low",
      },
      {
        name: "Medium",
        code: "medium",
      },
      {
        name: "High",
        code: "high",
      },
    ],
  },
  {
    type: "select",
    label: "Onboarding Poseidon Scale",
    placeholder: "Select",
    name: "scale",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "select",
    label: "technical Training",
    placeholder: "Select",
    name: "technicalTraining",
    options: [
      {
        name: "Low",
        code: "low",
      },
      {
        name: "Medium",
        code: "medium",
      },
      {
        name: "High",
        code: "high",
      },
    ],
  },
  {
    type: "select",
    label: "Onboarding Poseidon Transaction",
    placeholder: "Select",
    name: "transaction",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "select",
    label: "Store branding",
    placeholder: "Select",
    name: "branding",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "select",
    label: "Touchscreen kiosk",
    placeholder: "Select",
    name: "touch",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "multiSelect",
    label: "Presentation furniture list",
    placeholder: "Select",
    name: "furnitures",
    appendToSelf: true,
    filter: true,
  },
  {
    type: "textarea",
    label: "Comment",
    name: "comment",
  },
];
