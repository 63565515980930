import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NewAdressCard from "./NewAdressCard";
import AddAdress from "../../Catalogue/DeliveryDetails/AddAdress";
import NewAdressCardParticulier from "./NewAdressCardParticulier";
import { useTranslation } from "react-i18next";

const AdressSelector = ({ selectedReseller }) => {
  const { t } = useTranslation("common");
  const { resellers } = useSelector((state) => state.generalReducer);
  const { cart } = useSelector((state) => state.catalogueReducer);

  const [resellerDetails, setResellerDetails] = useState({});

  useEffect(() => {
    if (selectedReseller?.code) {
      const foundReseller = resellers.filter(
        (el) => el.id === selectedReseller?.code
      )[0];
      setResellerDetails(foundReseller);
    } else {
      setResellerDetails({});
    }
  }, [resellers, selectedReseller?.code]);

  console.log({ resellerDetails });

  return (
    <div>
      <h3 className="bold f20">{t("Delivery address")}</h3>
      <div className="d-flex flex-wrap gap-2 p-3">
        {resellerDetails
          ? resellerDetails?.attributes?.delivery_addresses?.data?.map(
              (address) => <NewAdressCard key={address.id} address={address} />
            )
          : null}
        {cart.particulier && cart.addresseDeLivraison ? (
          <NewAdressCardParticulier address={cart.addresseDeLivraison} />
        ) : null}
        <AddAdress resellerId={resellerDetails?.id} />
      </div>

      <h3 className="bold f20">{t("Billing address")}</h3>
      <div className="d-flex flex-wrap gap-2 p-3">
        {resellerDetails
          ? resellerDetails.attributes?.billingAddress?.data?.map((address) => (
              <NewAdressCard
                key={address.id}
                address={address}
                type="addresseDeFacturation"
              />
            ))
          : null}
        {cart.particulier && cart.addresseDeFacturation ? (
          <NewAdressCardParticulier
            address={cart.addresseDeFacturation}
            type="addresseDeFacturation"
          />
        ) : null}
        <AddAdress
          resellerId={resellerDetails?.id}
          type="addresseDeFacturation"
        />
      </div>
    </div>
  );
};

export default AdressSelector;
