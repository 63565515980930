export const transformSavedAttachments = (attachments) => {
  return attachments.map((attach) => {
    return {
      id: attach.id,
      title: attach.title,
      file: attach.file,
      fileName: attach.file.name,
      fileUrl: attach.file.url,
      fileId: attach.file.id,
      attachmentId: attach.id,
      date: attach?.date ?? attach.createdAt,
      mediaType: attach?.file?.mime?.includes("image")
        ? "image"
        : attach?.file?.mime?.includes("video")
        ? "video"
        : null,
    };
  });
};
export const transformSavedAttachGuide = (attachments) => {
  return attachments?.map((attach) => {
    return {
      id: attach?.id,
      title: attach?.attributes?.title,
      file: attach?.attributes?.file,
      fileName: attach?.attributes?.file?.data?.attributes?.name,
      fileUrl: attach?.attributes?.file?.data?.attributes?.url,
      fileId: attach?.attributes?.file?.data?.attributes?.id,
      attachmentId: attach?.id,
      date: attach?.attributes?.date,
      mediaType: attach?.attributes?.file?.data?.attributes?.mime?.includes(
        "image"
      )
        ? "image"
        : attach?.attributes?.file?.data?.attributes?.mime?.includes("video")
        ? "video"
        : null,
    };
  });
};
