import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import LoaderWrapper from "../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import RevendeurCard from "./RevendeurCard";
import { useDispatch, useSelector } from "react-redux";
import useOnScreen from "../../hooks/useOnScreen";
import { getResellers } from "../../REDUX/actions/reseller";
import { useTranslation } from "react-i18next";
import RevendeurListHeader from "./RevendeurListHeader";

const RevendeurList = ({ filters }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { resellers, loading } = useSelector((state) => state.resellerReducer);
  const [search, setSearch] = useState("");
  const [isArchived, setIsArchived] = useState(false);
  const { measureRef, isIntersecting } = useOnScreen();

  const userRole = user?.attributes?.userRole;
  const canSeeResellers = [1, 2]?.includes(userRole);

  useEffect(() => {
    if (canSeeResellers)
      if (isIntersecting)
        dispatch(getResellers(search, false, isArchived, filters));
  }, [dispatch, isIntersecting]);

  useEffect(() => {
    dispatch(getResellers(search, true, isArchived, filters));
  }, [filters?.business_affiliate, filters?.country]);

  useEffect(() => {
    dispatch({ type: "RESET_RESELLERS" });
  }, [dispatch]);
  if (!canSeeResellers) return null;

  return (
    <div className="py-5">
      <RevendeurListHeader
        search={search}
        setSearch={setSearch}
        isArchived={isArchived}
        setIsArchived={setIsArchived}
        filters={filters}
      />
      <div className="row bg-dimGrey py-3 px-4 mx-0 w-100 d-flex justify-content-between align-items-center mt-4">
        <span className="f14 col-2 text-brightGrey sBold">{t("Name")}</span>
        <span className="f14 col-1 text-brightGrey sBold">
          {t("Phone number")}
        </span>
        <span className="f14 col-2 text-brightGrey sBold">{t("E-mail")}</span>
        <span className="f14 col-2 text-brightGrey sBold">{t("Address")}</span>
        <span className="f14 col-2 text-brightGrey sBold">
          {t("Business affiliate")}
        </span>
        <span className="f14 col-2 text-brightGrey sBold">
          {t("Last order")}
        </span>
      </div>
      <LoaderWrapper loading={loading && resellers?.length < 10}>
        {resellers?.map((revendeur) => (
          <div key={revendeur?.id}>
            <RevendeurCard id={revendeur?.id} {...revendeur?.attributes} />
            <hr style={{ borderColor: "#abb8b2", margin: 0 }} width={"100%"} />
          </div>
        ))}
      </LoaderWrapper>
      {!loading && (
        <div style={{ width: "100%", height: "1px" }} ref={measureRef} />
      )}
      {loading && resellers?.length >= 10 && (
        <ProgressSpinner
          style={{
            display: "flex",
            alignItems: "center",
            width: "100px",
            height: "100px",
            marginTop: "30px",
          }}
          strokeWidth={3}
          className="loadingViewSpinner"
        />
      )}
    </div>
  );
};

export default RevendeurList;
